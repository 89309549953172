import { Fragment, useCallback, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import {
  Bars3Icon,
  CalendarIcon,
  HomeIcon,
  XMarkIcon,
  UserIcon,
  CreditCardIcon,
  ChartBarSquareIcon,
  ChatBubbleLeftEllipsisIcon,
  NewspaperIcon,
  AcademicCapIcon,
  QuestionMarkCircleIcon,
  SquaresPlusIcon,
  BellAlertIcon,
  BuildingLibraryIcon,
  EnvelopeIcon,
  ArrowsRightLeftIcon,
  GiftIcon,
} from "@heroicons/react/24/outline"
import { ActionList, Popover } from "@shopify/polaris"
import { getWhatsAppUrl } from "./WhatsAppWidget"
import mobileApp, { useMobileAppHelper } from "../lib/mobileApp"
import { useRouter } from "next/router"
import Link from "next/link"
import { ClipLoader } from "react-spinners"
import { useUser } from "hooks"
import { signOut } from "next-auth/react"
import { mockUser } from "lib/mockUser"
import { User } from "@prisma/client"
import useTranslateFunction from "@hooks/useTranslateFunction"
import { usePrompt } from "@hooks/usePrompt"
import { trpc } from "lib/trpc"
import { toast } from "react-toastify"
import { useFeatureFlagVariantKey } from "posthog-js/react"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export function AppShell({
  title,
  pageActions,
  children,
  banner,
  loading,
  showGoBack,
  customStyles,
}: {
  title?: string | React.ReactNode
  pageActions?: any
  children: React.ReactNode
  banner?: any
  loading?: boolean
  showGoBack?: boolean
  customStyles?: any
}) {
  const { t } = useTranslateFunction()
  const trpcUtils = trpc.useContext()
  const { openPrompt } = usePrompt()
  const changeAppModeMutation = trpc.user.changeAppMode.useMutation({
    onError: (err: any) => {
      console.log(err)
      toast.error("Hubo un error cambiando el mode de Confirmafy.")
    },
    onSuccess: async () => {
      // mixpanel.track("User deleted account")
      toast.success("Modo cambiado exitosamente.")
    },
  })
  const { user, isLoading } = useUser()
  const [userBeingMockedEmail, setUserBeingMockedEmail] = useState<
    null | string
  >(null)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { isMobileApp, platformOS } = useMobileAppHelper()
  const router = useRouter()
  const path = router.pathname
  const hasAccessToMangoFlag = false
  // useFeatureFlagEnabled("hasAccessToMango") ||
  // process.env.NODE_ENV === "development"

  useEffect(() => {
    if (
      user &&
      router.locale !== user.locale &&
      user.finishedOnBoarding === true
    ) {
      router.push(router.pathname, router.asPath, { locale: user?.locale })
    }
  }, [user])

  let navigation = [
    {
      name: t("AppShell_Configuración"),
      href: "/app",
      current: false,
      icon: HomeIcon,
    },
    {
      name: t("AppShell_Calendario"),
      href: "/app/calendar",
      current: false,
      icon: CalendarIcon,
    },
    {
      name: t("AppShell_Eventos"),
      href: "/app/events",
      current: false,
      onlyEvents: true,
      icon: EnvelopeIcon,
    },
    {
      name: t("AppShell_Contactos"),
      href: "/app/contacts",
      current: false,
      icon: UserIcon,
    },
    {
      name: t("AppShell_Contactos"),
      href: "/app/contacts",
      current: false,
      icon: UserIcon,
      onlyEvents: true,
    },
    {
      name: t("AppShell_Comprar invitaciones"),
      href: "/app/events/buy-invitations",
      current: false,
      onlyEvents: true,
      icon: CreditCardIcon,
    },
    user?.locale === "es"
      ? {
          name: t("AppShell_Autoagenda"),
          href: "/app/bookings",
          current: false,
          icon: SquaresPlusIcon,
        }
      : null,
    hasAccessToMangoFlag
      ? {
          name: t("Mango Bot"),
          href: "/app/mango-bot",
          current: false,
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="h-6 w-6 text-gray-400 group-hover:text-indigo-600"
              viewBox="0 0 16 16"
            >
              <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135" />
              <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5" />
            </svg>
          ),
        }
      : null,
    {
      name: t("AppShell_Suscripción"),
      href: "/app/subscribe",
      current: false,
      hideOnIOS: false,
      icon: CreditCardIcon,
    },
    {
      name: t("AppShell_Reportes"),
      href: "/app/reports",
      current: false,
      icon: ChartBarSquareIcon,
    },
    {
      name: t("AppShell_¿Necesitas Ayuda?"),
      href: getWhatsAppUrl(
        "Hola, estoy usando el App de Confirmafy y necesito ayuda."
      ),
      current: false,
      hideOnDesktop: true,
      icon: ChatBubbleLeftEllipsisIcon,
      onlyEvents: false,
    },
    user?.locale === "es"
      ? {
          name: t("AppShell_Manual"),
          href: "/manual",
          current: false,
          hideOnMobileApp: true,
          useRegularLink: true,
          icon: QuestionMarkCircleIcon,
        }
      : null,
    user?.locale === "es"
      ? {
          name: t("AppShell_Equipo"),
          href: "/app/organization",
          current: false,
          hideOnMobileApp: false,
          onlyOrganizationOwner: true,
          icon: BuildingLibraryIcon,
        }
      : null,
    {
      name: t("AppShell_Admin"),
      href: "/app/admin",
      current: false,
      hideOnMobileApp: false,
      useRegularLink: true,
      onlyAdmin: true,
      icon: AcademicCapIcon,
    },
    user?.subscription?.status === "active"
      ? {
          name: t("AppShell_Refiere y gana"),
          href: "/app/refer-a-friend",
          current: false,
          icon: GiftIcon,
        }
      : null,
    user?.choseEventsAppModeDuringOnboarding
      ? {
          name: t("AppShell_Cambiar a Modo Citas"),
          current: false,
          hideOnMobileApp: false,
          icon: ArrowsRightLeftIcon,
          onlyEvents: true,
          onClick: () => {
            openPrompt({
              title: t("AppShell_Cambiar a Modo Citas"),
              description: t(
                "AppShell_Confirmafy Citas esta diseñado para recordar citas y eventos a tus clientes."
              ),
              onConfirm: async () => {
                await changeAppModeMutation.mutateAsync({
                  appMode: "appointments",
                })
                await trpcUtils.user.loadUser.invalidate()
                router.push("/app")
              },
              onCancel: () => {},
            })
          },
        }
      : null,
    user?.choseEventsAppModeDuringOnboarding
      ? {
          name: t("AppShell_Cambiar a Modo Eventos"),
          current: false,
          hideOnMobileApp: false,
          icon: ArrowsRightLeftIcon,
          onClick: () => {
            openPrompt({
              title: t("AppShell_Cambiar a Modo Eventos"),
              description: t(
                "AppShell_Confirmafy Eventos esta diseñado para enviar invitaciones a Fiestas, Bodas, Cumpleaños, etc."
              ),
              onConfirm: async () => {
                await changeAppModeMutation.mutateAsync({
                  appMode: "events",
                })
                await trpcUtils.user.loadUser.invalidate()
                router.push("/app/events")
              },
              onCancel: () => {},
            })
          },
        }
      : null,
  ].filter((item) => item != null)

  const finalNavigation =
    isLoading || user == null
      ? []
      : getNavigation(user, isMobileApp, platformOS)

  function getNavigation(user: User, isMobileApp: boolean, platformOS: string) {
    if (user.appMode === "events") {
      return navigation.filter((item) => {
        return item.onlyEvents
      })
    }

    return navigation.filter((item) => {
      if (item.onlyEvents) {
        return false
      }

      if (isMobileApp && item.hideOnIOS && platformOS === "ios") {
        return false
      }

      if (item.hideOnMobileApp && isMobileApp) {
        return false
      }

      if (item.hideOnDesktop && !isMobileApp) {
        return false
      }

      if (item.onlyAdmin && user && !user.isAdmin) {
        return false
      }

      if (user?.permissions != null) {
        // Organization owners must have permissions set to NULL
        // This is for the peasants so we only show them the /app/calendar
        if (
          item.href === "/app/calendar" ||
          item.href.includes("/app/bookings") ||
          item.href.includes("/app/contacts")
        ) {
          return true
        }

        return false
      }

      return true
    })
  }

  function isCurrent(href: string) {
    if (path === "/app" && href === "/app") {
      return href === path
    } else if (path.indexOf(href) !== -1 && href !== "/app") {
      return true
    }

    return false
  }

  function getNameForCurrentRoute() {
    for (const item of navigation) {
      if (isCurrent(item.href)) {
        return item.name
      }
    }

    return ""
  }

  useEffect(() => {
    const email = mockUser.getUserBeingMockedEmail()

    if (email != null) {
      setUserBeingMockedEmail(email)
    }
  }, [])

  useEffect(() => {
    // members of organization that are not the owner
    // only have access to these pages:
    const allowedPaths = [
      "/app/calendar",
      "/app/contacts",
      "/app/contacts/[contactId]",
      "/app/appointment/[appointmentId]",
      "/app/account",
      "/app/mobile/connect-google-account",
      "/app/mobile/open-mobile-app",
      "/app/bookings",
    ]

    if (
      user &&
      user.organization &&
      user.organization.ownerUserId !== user.id &&
      !allowedPaths.some((path) => router.pathname.includes(path))
    ) {
      router.push("/app/calendar")
    }
  }, [user])

  if (!isLoading && user == null) {
    return (
      <>
        <div className={`min-h-screen`}>
          <div className="py-5">
            <main>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mt-20 h-96 text-center">
                  <p className="text-2xl font-bold">
                    {t("AppShell_Haz login para continuar")}
                  </p>
                  <div className="mt-6">
                    <Link legacyBehavior href="/login">
                      <a className="self-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-xl text-white hover:bg-indigo-700 sm:mr-0">
                        {t("AppShell_Login")}
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <span className="flex flex-shrink-0 items-center">
                      <img
                        className="w-10 sm:mr-2"
                        src="/logo.png"
                        alt="Confirmafy logo"
                      />
                      <div className=" text-lg font-semibold text-gray-800 sm:text-2xl md:mr-2 md:hidden lg:block">
                        Confirmafy
                      </div>
                    </span>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {finalNavigation.map((item) => {
                            if (item.useRegularLink) {
                              return (
                                <li key={item.name}>
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      isCurrent(item.href)
                                        ? "bg-gray-50 text-indigo-600"
                                        : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                    )}
                                    aria-current={
                                      isCurrent(item.href) ? "page" : undefined
                                    }
                                  >
                                    <item.icon
                                      className={classNames(
                                        isCurrent(item.href)
                                          ? "text-indigo-600"
                                          : "text-gray-400 group-hover:text-indigo-600",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              )
                            }

                            if (item.onClick) {
                              return (
                                <li key={item.name}>
                                  <button
                                    className={classNames(
                                      isCurrent(item.href)
                                        ? "bg-gray-50 text-indigo-600"
                                        : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                      "group flex gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6"
                                    )}
                                    onClick={item.onClick}
                                  >
                                    <item.icon
                                      className={classNames(
                                        isCurrent(item.href)
                                          ? "text-indigo-600"
                                          : "text-gray-400 group-hover:text-indigo-600",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </button>
                                </li>
                              )
                            }

                            return (
                              <li key={item.name}>
                                <Link legacyBehavior href={item.href}>
                                  <a
                                    className={classNames(
                                      isCurrent(item.href)
                                        ? "bg-gray-50 text-indigo-600"
                                        : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        isCurrent(item.href)
                                          ? "text-indigo-600"
                                          : "text-gray-400 group-hover:text-indigo-600",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                    {item.name === "Mango Bot" && (
                                      <span className="-ml-2 text-xxs">
                                        (beta)
                                      </span>
                                    )}
                                  </a>
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                      {/* <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">
                              Your teams
                            </div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                              {teams.map((team) => (
                                <li key={team.name}>
                                  <a
                                    href={team.href}
                                    className={classNames(
                                      team.current
                                        ? "bg-gray-50 text-indigo-600"
                                        : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        team.current
                                          ? "border-indigo-600 text-indigo-600"
                                          : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                        "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                                      )}
                                    >
                                      {team.initial}
                                    </span>
                                    <span className="truncate">
                                      {team.name}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li> */}
                      {user?.subscription?.status === "trialing" &&
                        user?.appMode === "appointments" && (
                          <li className="flex justify-center">
                            <Link
                              href="/app/subscribe"
                              className="mb-2 me-2 rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
                            >
                              {t("AppShell_Escoger plan")}
                            </Link>
                          </li>
                        )}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="w-10 sm:mr-2"
              src="/logo.png"
              alt="Confirmafy logo"
            />
            <div className=" text-lg font-semibold text-gray-800 md:mr-2 md:hidden lg:block">
              Confirmafy
            </div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {finalNavigation.map((item) => {
                    if (item.useRegularLink) {
                      return (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              isCurrent(item.href)
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                isCurrent(item.href)
                                  ? "text-indigo-600"
                                  : "text-gray-400 group-hover:text-indigo-600",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </li>
                      )
                    }

                    if (item.onClick) {
                      return (
                        <li key={item.name}>
                          <button
                            onClick={item.onClick}
                            className={classNames(
                              "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                              "group flex gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                "text-gray-400 group-hover:text-indigo-600",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </button>
                        </li>
                      )
                    }

                    return (
                      <li key={item.name}>
                        <Link legacyBehavior href={item.href}>
                          <a
                            className={classNames(
                              isCurrent(item.href)
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                              "group relative flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                isCurrent(item.href)
                                  ? "text-indigo-600"
                                  : "text-gray-400 group-hover:text-indigo-600",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                            {item.name === "Mango Bot" && (
                              <span className="-ml-2 text-xxs">(beta)</span>
                            )}
                          </a>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
              {/* <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">
                      Your teams
                    </div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {teams.map((team) => (
                        <li key={team.name}>
                          <a
                            href={team.href}
                            className={classNames(
                              team.current
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <span
                              className={classNames(
                                team.current
                                  ? "border-indigo-600 text-indigo-600"
                                  : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                              )}
                            >
                              {team.initial}
                            </span>
                            <span className="truncate">{team.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li> */}
              <li className="-mx-6 mt-auto">
                {user?.subscription?.status === "trialing" &&
                  user?.appMode === "appointments" && (
                    <div className="flex justify-center px-6">
                      <Link
                        href="/app/subscribe"
                        className="mb-2 flex-grow  rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
                      >
                        {t("Escoger plan")}
                      </Link>
                    </div>
                  )}

                <AccountPopOver
                  activator={
                    <a
                      href="#"
                      className="flex items-center px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                    >
                      {user?.image ? (
                        <img
                          className="h-7 w-7 rounded-full bg-gray-50"
                          src={user?.image ?? undefined}
                          alt=""
                        />
                      ) : (
                        <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center  rounded-full text-lg font-bold uppercase text-gray-600 ring-2 ring-indigo-500">
                          {user?.name ? user?.name[0] : ""}
                        </span>
                      )}
                      <span className="ml-2 overflow-hidden text-ellipsis whitespace-nowrap">
                        {user?.name}
                      </span>
                    </a>
                  }
                />
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
          {getNameForCurrentRoute()}
        </div>
        <a href="#">
          <AccountPopOver
            activator={
              user?.image ? (
                <img
                  className="h-8 w-8 rounded-full bg-gray-50"
                  src={user?.image ?? undefined}
                  alt=""
                />
              ) : (
                <span className="flex h-8 w-8 items-center justify-center rounded-full text-xl font-bold text-gray-600 ring-2 ring-indigo-500">
                  {user?.name ? user?.name[0] : ""}
                </span>
              )
            }
          ></AccountPopOver>
        </a>
      </div>

      <main
        className="lg:pl-60"
        style={!loading && customStyles ? customStyles : {}}
      >
        {loading ? (
          <div className="mt-20 h-96 text-center">
            <ClipLoader color="#000000" loading size={50} />
          </div>
        ) : (
          <div>
            {userBeingMockedEmail != null &&
              user?.email === userBeingMockedEmail && (
                <div className="flex w-full bg-gray-300 px-4 py-4">
                  <BellAlertIcon className="w-5"></BellAlertIcon> Estas en la
                  cuenta de: <b className="ml-2">{userBeingMockedEmail}</b>
                  <button
                    type="button"
                    className=" ml-auto rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                      mockUser.stopMockingUser()
                    }}
                  >
                    Dejar de usar esta cuenta
                  </button>
                </div>
              )}

            {banner}

            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
              <header>
                <div className="flex flex-col">
                  {showGoBack || pageActions ? (
                    <div className="mb-2 flex">
                      {showGoBack && (
                        <button
                          onClick={() => router.back()}
                          className=" rounded p-1 hover:bg-gray-300"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10 19l-7-7m0 0l7-7m-7 7h18"
                            />
                          </svg>
                        </button>
                      )}
                      <div className="ml-auto">{pageActions}</div>
                    </div>
                  ) : null}

                  <div className="flex w-full items-center justify-between">
                    <h1 className="text-2xl font-bold leading-tight text-gray-900 sm:text-3xl">
                      {title}
                    </h1>
                  </div>
                </div>
              </header>

              {children}
            </div>
          </div>
        )}
      </main>

      {/* <aside className="fixed inset-y-0 left-72 hidden w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block"> */}
      {/* Secondary column (hidden on smaller screens) */}
      {/* </aside> */}
    </div>
  )
}

function AccountPopOver({ activator }: { activator: React.ReactNode }) {
  const { t } = useTranslateFunction()
  const router = useRouter()
  const { isMobileApp } = useMobileAppHelper()
  const [popoverActive, setPopoverActive] = useState(false)

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  )

  const activatorWrapped = (
    <button onClick={togglePopoverActive} className="p-1">
      {activator}
    </button>
  )

  return (
    <div className="PopOverContainer">
      <Popover
        active={popoverActive}
        activator={activatorWrapped}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
      >
        <div className="w-32">
          <ActionList
            actionRole="menuitem"
            items={[
              {
                content: t("AppShell_Cuenta"),
                onAction: async () => {
                  router.push("/app/account")
                },
              },
              {
                content: t("AppShell_Logout"),
                onAction: async () => {
                  if (isMobileApp) {
                    mobileApp.signOut()
                  } else {
                    await signOut({ callbackUrl: "/" })
                  }
                },
              },
            ]}
          />
        </div>
      </Popover>
    </div>
  )
}
